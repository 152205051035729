import PropTypes from 'prop-types';

// we use this when there are nested cyclic props from graphql
// example: { league: { game: { league: {  ... } } } }
export const CYCLIC_PROP = PropTypes.shape({
  _id: PropTypes.string,
});

export const ColorProps = PropTypes.shape({
  hex: PropTypes.string,
  name: PropTypes.string,
});

export const LabelValueProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const LabelOnClickProps = PropTypes.shape({
  label: PropTypes.string,
  onClick: PropTypes.func,
});

export const NameUrlProps = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const CoordinatesProps = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const GenderCompositionProps = PropTypes.shape({
  f: PropTypes.number,
  m: PropTypes.number,
  o: PropTypes.number,
});

export const PricePhaseProps = PropTypes.shape({
  individualPrice: PropTypes.number,
  prepaidTeamPrice: PropTypes.number,
  individualVoloPassPrice: PropTypes.number,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  allow: PropTypes.bool,
  allowIndividual: PropTypes.bool,
  allowPrepaidTeam: PropTypes.bool,
});

export const EmailFromProps = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
});

export const EmailProps = PropTypes.shape({
  _id: PropTypes.string,
  to: PropTypes.arrayOf(PropTypes.string),
  from: EmailFromProps,
  reply_to: EmailFromProps,
  subject: PropTypes.string,
  html: PropTypes.string,
  leagues: PropTypes.arrayOf(PropTypes.string),
  leagueName: PropTypes.string,
  emailFilters: PropTypes.shape({
    isMarketingEmail: PropTypes.bool,
    teamIds: PropTypes.arrayOf(PropTypes.string),
    sports: PropTypes.arrayOf(PropTypes.string),
    cities: PropTypes.arrayOf(PropTypes.string),
    stages: PropTypes.arrayOf(PropTypes.string),
    genders: PropTypes.arrayOf(PropTypes.string),
    playerKinds: PropTypes.arrayOf(PropTypes.string),
    seasons: PropTypes.arrayOf(PropTypes.string),
  }),
  emailFilterString: PropTypes.string,
  manualEmails: PropTypes.arrayOf(PropTypes.string),
  personalizations: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      substitutions: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    })
  ),
  timestamp: PropTypes.string,
});

export const ChildrenNodeProps = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node])),
]);
