import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { FontAwesomeIcon } from '@rivallapp/volosports-components';
import Picture from '../../../components/Picture/Picture';
import styles from './styles.module.scss';
import { StaticUrls } from '../../../shared/static-urls-enum';
import { LinkToRentals } from '../../../components';

const cx = classNames.bind(styles);

const learnMoreLinks = {
  'Our Story': '/about-us',
  FAQs: '/faq',
  'Contact Us': '/contact-us',
  Policies: '/policies',
};

const socialMediaIcons = {
  facebook: {
    link: StaticUrls.FACEBOOK,
    icon: <FontAwesomeIcon icon={faFacebook} colorScheme="white" size="45px" />,
  },
  linkedin: {
    link: StaticUrls.LINKEDIN,
    icon: <FontAwesomeIcon icon={faLinkedin} colorScheme="white" size="45px" />,
  },
  instagram: {
    link: StaticUrls.INSTAGRAM,
    icon: <FontAwesomeIcon icon={faInstagram} colorScheme="white" size="45px" />,
  },
};

const Footer = () => (
  <div className={cx('footer')}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className={cx('logofooterRow')}>
            <Picture
              className={cx('voloLogo')}
              src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-white-logo.png"
              alt="volo white logo"
            />
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className={cx('footerRow')}>
            <div className="row">
              <div className="col-6">
                <h3>Learn More</h3>
                <ul>
                  {Object.entries(learnMoreLinks).map(([title, link]) => (
                    <li key={title}>
                      <Link to={link}>{title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-6">
                <h3>Get Involved</h3>
                <ul>
                  <li>
                    <Link to="/discover">Programs</Link>
                  </li>
                  <li>
                    <a href={StaticUrls.VOLOKIDS} target="_blank" rel="noopener noreferrer">
                      Volo Kids
                    </a>
                  </li>
                  <li>
                    <Link to="/volo-pass">Membership</Link>
                  </li>
                  <li>
                    <LinkToRentals>Court Rentals</LinkToRentals>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className={cx('logofooterRow', 'social')}>
            <div className={cx('social')}>Follow Us</div>
            <div className="row">
              {Object.entries(socialMediaIcons).map(([key, brandObj]) => (
                <div key={key} className="col-4">
                  <a href={brandObj.link} target="_blank" rel="noopener noreferrer">
                    {brandObj.icon}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
