// eslint-disable-next-line import/prefer-default-export
export enum CreditTypeEnum {
  PROGRAM_CREDIT = 'program_credit',
  SELF_WITHDRAWAL_CREDIT = 'self_withdrawal_credit',
  DROPIN_CREDIT = 'dropin_credit',
  REFERRAL_CREDIT = 'referral_credit',
  RENTAL_CREDIT = 'rental_credit',
  GIFT_CARD = 'gift_card',
  /** This value is for historical data only, it should NOT be used for new credits. */
  UNKNOWN_CREDIT = 'unknown_credit',
}
