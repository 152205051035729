export const RegistrationActions = {
  UPDATE_REGISTRATION_STATE: 'UPDATE_REGISTRATION_STATE',
  UPDATE_NEW_GROUP_DETAILS: 'UPDATE_NEW_GROUP_DETAILS',
  UPDATE_NEW_TEAM_DETAILS: 'UPDATE_NEW_TEAM_DETAILS',
  UPDATE_CUSTOM_RESPONSE: 'UPDATE_CUSTOM_RESPONSE',
  RESET_REGISTRATION_STATE: 'RESET_REGISTRATION_STATE',
};

export const initialState = {
  typeDescription: '',
  typeLabel: '',
  gender: undefined,
  hasStripeId: false,
  /** @deprecated 2024-08-01 - Please use `User.isVoloPassMember`. That value comes directly from Stripe and is more accurate */
  membershipExpires: null,
  /** @deprecated 2024-08-01 - No longer in use */
  chosenPlan: 'base',
  currentStepIndex: 0,
  processingPayment: false,
  group: null,
  blockRegistrations: false,

  // 01 - Select Registrant Type
  registrantType: null,

  // 01A - Join Group / Team
  joiningGroupId: undefined,
  selectedGroupId: null,
  passwordInput: '',
  passwordRequired: false,
  passwordCorrect: false,
  fullTeam: false,
  isLocked: false,

  // 01B - New Prepaid Team
  newPrepaidTeam: {},

  // 01C - New Unpaid Group
  newGroup: {},

  // 02A - Preferences
  wypSelection: null,
  responses: [],

  // 02B - Skill Levels
  divisionSelection: null,

  // 03 - Donation
  donationAmount: undefined,
  /** @type {import('@rivallapp/volosports-components').DonationFrequencyEnum | undefined} */
  donationFrequency: undefined,

  // 04 Payment - Vp Trial
  isVoloPassTrialActive: false,
  /** @deprecated 2024-08-01 - Handled internally in `<PaymentSummary />`. This value may not be accurate. */
  shouldGrantTrial: false,
  /** @deprecated 2024-08-01 - Handled internally in `<PaymentSummary />`. This value may not be accurate. */
  addVpTrial: false,
  showVpPopup: false,

  // 04 Payment - Vp Membership
  /** @deprecated 2024-08-01 - Use `usePaymentSummaryProps.pricingSelected === MembershipEnum.VOLO_PASS` instead. */
  addVpMembership: false,
  showConfirmation: false,

  // 04 Payment - Payment
  deletePaymentInfo: false,
  paymentSources: null,

  // 04 Payment - Credits
  creditApplied: false,
  creditAppliedAmount: 0,
  availableCreditsAmount: 0,

  // 04 Payment - Promo
  // Represents only the string being attempted, regardless of if it is valid or not. Use `promoDetails` for the actual object.
  appliedPromo: null,

  // 04 Payment - Waiver
  waiverAgreed: false,

  // 04 Payment - Late Cancellations & No Show Policy Acknowledgement. Only used on Daily Registration.
  cancellationPolicyAgreed: false,

  // 04 Payment - Volo Pass Terms & Conditions
  /** @deprecated - Use `usePaymentSummaryProps.areMemberTermsAgreed` instead */
  termsAgreed: false,

  // 04 Payment - daily only - Team Preference
  selectedSlot: null,
};

const RegistrationReducer = (state, action) => {
  switch (action.type) {
    case RegistrationActions.UPDATE_REGISTRATION_STATE:
      return {
        ...state,
        ...action.update,
      };
    case RegistrationActions.UPDATE_NEW_GROUP_DETAILS:
      return {
        ...state,
        newGroup: {
          ...state.newGroup,
          ...action.update,
        },
      };
    case RegistrationActions.UPDATE_NEW_TEAM_DETAILS:
      return {
        ...state,
        newPrepaidTeam: {
          ...state.newPrepaidTeam,
          ...action.update,
        },
      };
    case RegistrationActions.UPDATE_CUSTOM_RESPONSE: {
      const updatedResponses = state.responses;
      const currentIndex = action.responseIndex || 0;

      updatedResponses[currentIndex] = {
        questionTitle: action.questionTitle,
        response: action.update,
      };
      return {
        ...state,
        responses: updatedResponses,
      };
    }
    case RegistrationActions.RESET_REGISTRATION_STATE:
      return {
        ...initialState,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default RegistrationReducer;
