export enum LeagueStatusEnum {
  /** Temporary status used to save data in the create flow.  Gets deleted by a job after 24 hours */
  DRAFT = 'draft',
  /** Program has been 'created' through csv upload but 'create' button has not been clicked yet */
  STAGED = 'staged',
  /** league registration has not opened yet */
  PENDING = 'pending',
  /** league is open for registration */
  REGISTRATION_OPEN = 'registration_open',
  /** registration has closed but league has not started */
  READY = 'ready',
  /** league has started */
  ACTIVE = 'active',
  /** teams or schedules are not complete */
  NEEDS_APPROVAL = 'needs_approval',
  /** league was complete and archived */
  COMPLETED = 'completed',
  /** league was not complete and archived */
  CANCELLED = 'cancelled',
  ERROR = 'error',
}

export enum LeagueStageEnum {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  STAGED = 'staged',
}

// translates a league status to its stage counterpart
export const LeagueStatusToStageKey = Object.freeze({
  [LeagueStatusEnum.PENDING]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.REGISTRATION_OPEN]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.NEEDS_APPROVAL]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.READY]: LeagueStageEnum.UPCOMING,
  [LeagueStatusEnum.ACTIVE]: LeagueStageEnum.ACTIVE,
  [LeagueStatusEnum.COMPLETED]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.CANCELLED]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.ERROR]: LeagueStageEnum.ARCHIVED,
  [LeagueStatusEnum.STAGED]: LeagueStageEnum.STAGED,
});

export function getLeagueStatusFromStage(stage: keyof typeof LeagueStageEnum) {
  const leagueStatuses = Object.entries(LeagueStatusToStageKey)
    .filter(([, stageValue]) => stageValue.toUpperCase() === stage.toUpperCase())
    .map(
      ([statusKey]) => LeagueStatusEnum[statusKey.toUpperCase() as keyof typeof LeagueStatusEnum]
    );

  return leagueStatuses;
}

export const getLeagueStatusesFromStage = (stage: LeagueStageEnum) => {
  return Object.entries(LeagueStatusToStageKey)
    .filter(([, _stage]) => _stage === stage)
    .map(([status]) => status as LeagueStatusEnum);
};

export default LeagueStatusEnum;
