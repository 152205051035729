import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import styles from './overflow-menu.module.scss';
import Button from '../../components/Button';

class OverflowMenu extends Component {
  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.showMore = this.showMore.bind(this);
    this.menuRef = React.createRef();
    this.state = {
      menuOpen: false,
      showMore: true,
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handlePageClick);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handlePageClick);
  }

  handlePageClick(e) {
    const menu = this.menuRef.current;
    if (!menu || menu.contains(e.target)) {
      return;
    }
    this.setState({ menuOpen: false });
  }

  toggleMenu(e) {
    if (e) e.preventDefault();
    const { onButtonClick } = this.props;
    const { menuOpen } = this.state;
    if (onButtonClick) onButtonClick(!menuOpen);
    this.setState({ menuOpen: !menuOpen });
  }

  showMore() {
    this.setState({ showMore: true });
  }

  renderButton() {
    const {
      buttonType,
      large,
      largeDots,
      small,
      buttonTraits,
      buttonStyle,
      CustomButton,
      isPlayerDashboard = false,
    } = this.props;
    const { menuOpen } = this.state;

    if (CustomButton) {
      return React.cloneElement(CustomButton, {
        onClick: this.toggleMenu,
      });
    }

    return (
      <Button
        isPlayerDashboard={isPlayerDashboard}
        {...buttonTraits.reduce((acc, prop) => ({ ...acc, [prop]: true }), {})}
        style={buttonStyle}
        onClick={e => {
          e.stopPropagation();
          this.toggleMenu();
        }}
        className={cx(styles.trigger)}
        inactive={!menuOpen}
      >
        {buttonType === 'gear' ? (
          <span className={cx(styles.icon, styles.iconSettings)} />
        ) : (
          <div
            className={cx(styles.dotWrap, styles[buttonType], {
              [styles.small]: small,
              [styles.large]: large,
              [styles.largeDots]: largeDots,
            })}
          >
            <span className={cx(styles.dot)} />
            <span className={cx(styles.dot)} />
            <span className={cx(styles.dot)} />
          </div>
        )}
      </Button>
    );
  }

  render() {
    const {
      position,
      persistAfterClick,
      large,
      small,
      loading,
      options: unfilteredOptions,
      menuStyle,
      history,
      style,
    } = this.props;
    const { menuOpen, showMore } = this.state;

    let options = unfilteredOptions || [];
    const hasFold = !showMore && !!options.find(op => op.belowFold);
    options = showMore || !hasFold ? options : options.filter(op => !op.belowFold);
    return (
      <div
        className={cx(styles.overflowMenu, { [styles.large]: large, [styles.small]: small })}
        ref={this.menuRef}
        style={style}
      >
        {this.renderButton()}

        <div
          className={cx(styles.menu, styles[position], {
            [styles.visible]: menuOpen,
          })}
          style={menuStyle}
        >
          {options.map(({ key, ...option }, i) => (
            <MenuItem
              key={key || `op${i}`}
              closeMenu={this.toggleMenu}
              persistAfterClick={persistAfterClick}
              history={history}
              {...option}
            />
          ))}
          {!showMore && hasFold && (
            <div className={cx(styles.menuItem)} onClick={this.showMore}>
              <i>Show more...</i>
            </div>
          )}
          {loading && (
            <div className={cx(styles.menuItem)} style={{ pointerEvents: 'none' }}>
              <small className={cx('text-muted')}>Loading...</small>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const MenuItem = props => {
  const {
    label,
    onClick,
    href,
    closeMenu,
    disabled,
    persistAfterClick,
    icon,
    subtitle,
    to,
    history,
  } = props;

  const clickHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    if (disabled) return;
    if (href) {
      window.location = href;
      return;
    }
    if (to) {
      history.replace(to);
    } else if (onClick) {
      onClick();
    }
    if (!persistAfterClick) {
      closeMenu();
    }
  };

  const Icon = icon && <img className={cx(styles.itemIcon)} src={icon} alt="" />;
  const HelpText = !!subtitle && <div className={styles.helpText}>{subtitle}</div>;

  const content = (
    <React.Fragment>
      {Icon}
      {label}
      {HelpText}
    </React.Fragment>
  );

  const opProps = {
    className: cx(styles.menuItem, {
      [styles.withHelpText]: !!subtitle,
      [styles.withIcon]: !!icon,
      [styles.disabled]: disabled,
    }),
    onClick: clickHandler,
  };

  return href ? (
    <a {...opProps} tabIndex="-1">
      {content}
    </a>
  ) : (
    <div {...opProps}>{content}</div>
  );
};

OverflowMenu.propTypes = {
  position: PropTypes.oneOf(['top', 'topLeft', 'topRight', 'bottom', 'bottomLeft', 'bottomRight']),
  /* If true, menu will not automatically hide after an option is selected */
  persistAfterClick: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      onClick: PropTypes.func,
      href: PropTypes.string,
      /* overrides the prop set on the component if present */
      persistAfterClick: PropTypes.bool,
      belowFold: PropTypes.bool,
      icon: PropTypes.string,
      subtitle: PropTypes.string,
      // deprecate in favor of subtitle
      helpText: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
  large: PropTypes.bool,
  buttonTraits: PropTypes.array,
  buttonStyle: PropTypes.shape({}),
  menuStyle: PropTypes.shape({}),
  CustomButton: PropTypes.node,
  onButtonClick: PropTypes.func,
  isPlayerDashboard: PropTypes.bool,
};

OverflowMenu.defaultProps = {
  position: 'bottomLeft',
  persistAfterClick: false,
  loading: false,
  large: false,
  buttonTraits: ['square', 'link'],
  buttonStyle: {},
  menuStyle: {},
  CustomButton: null,
  onButtonClick: null,
  isPlayerDashboard: false,
};

export default withRouter(OverflowMenu);
