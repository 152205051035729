import { gql, type TypedDocumentNode } from '@apollo/client';

export const REGISTER_FOR_PROGRAM = gql`
  mutation currentUserRegisterForProgram($input: CurrentUserRegisterForProgramInput!) {
    currentUserRegisterForProgram(input: $input) {
      programRegistration {
        registrant {
          _id
        }
      }
    }
  }
`;

export const REGISTER_FOR_DROP_IN = gql`
  mutation currentUserConfirmDropin($input: CurrentUserConfirmDropinInput!) {
    currentUserConfirmDropin(input: $input) {
      errored
      registrant {
        _id
      }
    }
  }
`;

export const GET_DROP_IN_SLOT = gql`
  query DropInSlot($id: ID!) {
    dropInSlot(_id: $id) {
      gameId
    }
  }
`;

export type GetGameDetailsData = {
  game: {
    _id: string;
    end_time: Date | string | null;
    start_time: Date | string | null;
    startTimeStr: string | null;
    endTimeStr: string | null;
    field_name: string;
    dropInCapacity: {
      a: number;
      f: number;
      m: number;
    };
    teams: {
      _id: string;
      name: string;
    }[];
    teamCapacities: {
      teamId: string;
      dropInSlots: {
        gender: string;
      }[];
    }[];
    location: {
      _id: string;
      name: string;
      timezone: string;
      neighborhood: string | null;
      city: string;
    } | null;
    league: {
      _id: string;
      displayName: string;
      startDateStr: string | null;
      header: string | null;
      photoUrl: string | null;
      isDaily: boolean;
      divisionDescriptions: string[] | null;
      is_volo_pass_exclusive: boolean;
      is_premier: boolean;
      organization: {
        _id: string;
        name: string;
        icon: string | null;
        isVoloPassTrialActive: boolean;
        voloPassTiers?: {
          isActive: boolean;
          monthlyDollarPrice: number | null;
          monthlyFee: number | null;
          monthlyPlanId: string | null;
        };
      };
      venue: {
        _id: string;
        name: string | null;
        formatted_address: string | null;
      };
    };
    availableDropInSlots: {
      _id: string;
      note: string;
      gender: string; // TODO enum?
      team: {
        _id: string;
        name: string;
      };
    }[];
  };
};

export const GET_GAME_DETAILS: TypedDocumentNode<GetGameDetailsData, { gameId: string }> = gql`
  query game($gameId: ID!) {
    game(_id: $gameId) {
      _id
      end_time
      start_time
      startTimeStr
      endTimeStr
      field_name
      dropInCapacity {
        a
        f
        m
      }
      location {
        _id
        name
        timezone
        neighborhood
        city
      }
      teams {
        _id
        name
      }
      teamCapacities {
        teamId
        dropInSlots {
          gender
        }
      }
      league {
        _id
        displayName
        startDateStr
        header
        photoUrl
        isDaily
        divisionDescriptions
        is_volo_pass_exclusive
        is_premier
        organization {
          _id
          name
          icon
          isVoloPassTrialActive
          voloPassTiers {
            isActive
            monthlyDollarPrice
            monthlyFee
            monthlyPlanId
          }
        }
        venue {
          name
          formatted_address
        }
      }
      availableDropInSlots {
        _id
        note
        gender
        team {
          _id
          name
        }
      }
    }
  }
`;

export const IS_IN_PROGRAM = gql`
  query currentUserIsInProgram($input: CurrentUserIsInProgramInput!) {
    currentUserIsInProgram(input: $input) {
      isInProgram
    }
  }
`;

export const MY_DROPIN_RSVPS = gql`
  query myRsvps($pagination: PaginationInput!) {
    myRsvps(pagination: $pagination) {
      rsvps {
        gameId
      }
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      isVoloPassMember
    }
  }
`;

export type GetLeagueDetailsData = {
  league: {
    _id: string;
    name: string;
    deepLink: string;
    programType: string;
    displayName: string;
    isDaily: boolean;
    divisionDescriptions: string[] | null;
    donationsDisabled: boolean;
    registrantCount: number | null;
    header: string | null;
    photoUrl: string | null;
    startDateStr: string | null;
    start_time_estimate: string;
    end_time_estimate: string;
    details: { description: string | null }[];
    is_volo_pass_exclusive: boolean;
    is_premier: boolean;
    registration: {
      _id: string;
      registration_open: Date | string;
      registration_close: Date | string;
      minSize: number;
      maxSize: number;
    };
    venue: {
      _id: string;
      name: string;
      timezone: string;
      formatted_address: string | null;
      neighborhood: string | null;
      city: string | null;
    };
    schedules: {
      _id: string;
      startDateStr: string;
      startTimeStr: string;
      endTimeStr: string;
    }[];
    organization: {
      _id: string;
      isVoloPassTrialActive: boolean;
      voloPassTiers: {
        isActive: boolean;
        monthlyDollarPrice: number | null;
        monthlyFee: number | null;
        monthlyPlanId: string | null;
      };
      approvedVenues: {
        _id: string;
        name: string;
        timezone: string;
        formatted_address: string | null;
        neighborhood: string | null;
        city: string | null;
      }[];
    };
  };
};

export const GET_LEAGUE_DETAILS: TypedDocumentNode<GetLeagueDetailsData, { leagueId: string }> =
  gql`
    query league($leagueId: ID!) {
      league(_id: $leagueId) {
        _id
        name
        deepLink
        programType
        displayName
        isDaily
        divisionDescriptions
        donationsDisabled
        registrantCount
        header
        photoUrl
        startDateStr
        start_time_estimate
        end_time_estimate
        is_volo_pass_exclusive
        is_premier
        details {
          description
        }
        registration {
          registration_open
          registration_close
          minSize
          maxSize
        }
        venue {
          _id
          name
          formatted_address
          neighborhood
          timezone
          city
        }
        schedules {
          _id
          startDateStr
          startTimeStr
          endTimeStr
        }
        organization {
          _id
          isVoloPassTrialActive
          voloPassTiers {
            isActive
            monthlyDollarPrice
            monthlyFee
            monthlyPlanId
          }
          approvedVenues {
            _id
            name
            formatted_address
            neighborhood
            timezone
            city
          }
        }
      }
    }
  `;

export const GET_PROGRAM_ROSTER = gql`
  query programRoster($leagueId: ID!) {
    programRoster(leagueId: $leagueId) {
      userId
      gender
      picture
      userDisplayInfo {
        displayName
        displayEmail
        displayPhone
      }
    }
  }
`;
