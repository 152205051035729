export const ErrorCodes = Object.freeze({
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UPDATE_FAILED: 'UPDATE_FAILED',
  NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
  USER_INFO_LOAD_FAILED: 'USER_INFO_LOAD_FAILED',
  AUTHORIZATION_FAILED: 'AUTHORIZATION_FAILED',
  UNAUTHORIZED_ACTION: 'UNAUTHORIZED_ACTION',
  INVALID_OBJECT_ID: 'INVALID_OBJECT_ID',
  INVALID_TEAM_ID: 'INVALID_TEAM_ID',
  USERNAME_TAKEN: 'USERNAME_TAKEN',
  EMAIL_TAKEN: 'EMAIL_TAKEN',
  DEEPLINK_ERROR: 'DEEPLINK_ERROR',
  CANT_DELETE_TEAM_WITH_GAMES: 'CANT_DELETE_TEAM_WITH_GAMES',
  PLAYER_ALREADY_IN_LEAGUE: 'PLAYER_ALREADY_IN_LEAGUE',
  GROUP_ALREADY_IN_LEAGUE: 'GROUP_ALREADY_IN_LEAGUE',
  DUPLICATE_TEAM_NAME: 'DUPLICATE_TEAM_NAME',
  ACTIVITY_TYPE_NOT_FOUND: 'ACTIVITY_TYPE_NOT_FOUND',
  SEASON_NOT_FOUND: 'SEASON_NOT_FOUND',
  ORGANIZATION_NOT_FOUND: 'ORGANIZATION_NOT_FOUND',
  VENUE_NOT_FOUND: 'VENUE_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  LEAGUE_NOT_FOUND: 'LEAGUE_NOT_FOUND',
  RECURRING_DROPIN_NOT_FOUND: 'RECURRING_DROPIN_NOT_FOUND',
  NOTIFICATION_NOT_FOUND: 'NOTIFICATION_NOT_FOUND',
  GROUP_NOT_FOUND: 'GROUP_NOT_FOUND',
  NEIGHBORHOOD_NOT_FOUND: 'NEIGHBORHOOD_NOT_FOUND',
  PROMO_NOT_FOUND: 'PROMO_NOT_FOUND',
  INCORRECT_PROMO_TYPE: 'INCORRECT_PROMO_TYPE',
  REFERRAL_MISSING_CREATOR: 'REFERRAL_MISSING_CREATOR',
  INVALID_DATE_RANGE: 'INVALID_DATE_RANGE',
  INVALID_TIMESLOT_TIME_RANGE: 'INVALID_TIMESLOT_TIME_RANGE',
  INVALID_DATE_STRING_FORMAT: 'INVALID_DATE_STRING_FORMAT',
  INVALID_TIME_STRING_FORMAT: 'INVALID_TIME_STRING_FORMAT',
  INVALID_PRICE_FORMAT: 'INVALID_PRICE_FORMAT',
  INVALID_QUESTION_TITLE: 'INVALID_QUESTION_TITLE',
  INVALID_DROPDOWN_OPTION: 'INVALID_DROPDOWN_OPTION',
  INVALID_RATING_TYPE: 'INVALID_RATING_TYPE',
  INVALID_GAME_TIMES: 'INVALID_GAME_TIMES',

  STRIPE_CUSTOMER_NOT_FOUND: 'STRIPE_CUSTOMER_NOT_FOUND',
  STRIPE_ADD_PAYMENT_SOURCE_ERROR: 'STRIPE_ADD_PAYMENT_SOURCE_ERROR',
  STRIPE_DELETE_PAYMENT_SOURCE_ERROR: 'STRIPE_DELETE_PAYMENT_SOURCE_ERROR',
  STRIPE_DEFAULT_PAYMENT_SOURCE_ERROR: 'STRIPE_DEFAULT_PAYMENT_SOURCE_ERROR',
  STRIPE_SUBSCRIPTION_NOT_FOUND: 'STRIPE_SUBSCRIPTION_NOT_FOUND',
  STRIPE_COUPON_NOT_FOUND: 'STRIPE_COUPON_NOT_FOUND',
  STRIPE_MULTIPLE_SUBSCRIPTION_ITEM_IDS: 'STRIPE_MULTIPLE_SUBSCRIPTION_ITEM_IDS',

  INVALID_NUM_WEEKS: 'INVALID_NUM_WEEKS',
  INVALID_TEAM_SIZE: 'INVALID_TEAM_SIZE',
  INVALID_CONTACT_INFO: 'INVALID_CONTACT_INFO',

  DUPLICATE_REGISTRANT: 'DUPLICATE_REGISTRANT',
  DUPLICATE_DROPIN: 'DUPLICATE_DROPIN',
  DROPIN_CLAIMED: 'DROPIN_CLAIMED',
  DROPIN_CLOSED: 'DROPIN_CLOSED',
  DROPIN_NOT_FOUND: 'DROPIN_NOT_FOUND',
  NO_REGISTRANT_FOUND: 'NO_REGISTRANT_FOUND',
  NO_DROPIN_REGISTRANT_FOUND: 'NO_DROPIN_REGISTRANT_FOUND',
  DROPIN_CLOSING: 'DROPIN_CLOSING',
  DROPIN_HOLD: 'DROPIN_HOLD',
  DROPIN_MAX: 'DROPIN_MAX',
  DROPIN_OPENING: 'DROPIN_OPENING',
  UNAUTHORIZED_DROPIN_UPDATE: 'UNAUTHORIZED_DROPIN_UPDATE',
  RECURRING_DROPIN_FAILED: 'RECURRING_DROPIN_FAILED',
  RECURRING_DROPIN_GAME_COUNT: 'RECURRING_DROPIN_GAME_COUNT',
  RECURRING_DROPIN_BAD_INPUT: 'RECURRING_DROPIN_BAD_INPUT',
  NO_TOURNAMENT_DROPINS: 'NO_TOURNAMENT_DROPINS',
  INVALID_DROPIN_GENDER: 'INVALID_DROPIN_GENDER',
  INVALID_PROGRAM_GENDER: 'INVALID_PROGRAM_GENDER',
  NO_VOLO_PASS: 'NO_VOLO_PASS',
  MULTIPLE_REGISTRATION_TYPES_SUBMITTED: 'MULTIPLE_REGISTRATION_TYPES_SUBMITTED',

  GAME_NOT_FOUND: 'GAME_NOT_FOUND',
  SCHEDULE_NOT_FOUND: 'SCHEDULE_NOT_FOUND',

  UNKNOWN_CHARGE_ERROR: 'UNKNOWN_CHARGE_ERROR',
  CARD_DECLINED__AUTHENTICATION_REQUIRED: 'CARD_DECLINED__AUTHENTICATION_REQUIRED',
  CARD_DECLINED__REASON_UNKNOWN: 'CARD_DECLINED__REASON_UNKNOWN',
  CARD_DECLINED__CONTACT_ISSUER: 'CARD_DECLINED__CONTACT_ISSUER',
  CARD_DECLINED__TRY_AGAIN: 'CARD_DECLINED__TRY_AGAIN',
  CARD_DECLINED__TRY_AGAIN_LATER: 'CARD_DECLINED__TRY_AGAIN_LATER',
  CARD_DECLINED__DUPLICATE_CHARGE: 'CARD_DECLINED__DUPLICATE_CHARGE',
  CARD_DECLINED__EXPIRED: 'CARD_DECLINED__EXPIRED',
  CARD_DECLINED__SUSPICIOUS: 'CARD_DECLINED__SUSPICIOUS',
  CARD_DECLINED__BAD_CVC: 'CARD_DECLINED__BAD_CVC',
  CARD_DECLINED__BAD_PIN: 'CARD_DECLINED__BAD_PIN',
  CARD_DECLINED__BAD_ZIP: 'CARD_DECLINED__BAD_ZIP',
  CARD_DECLINED__INSUFFICIENT_FUNDS: 'CARD_DECLINED__INSUFFICIENT_FUNDS',
  CARD_DECLINED__BAD_EXP: 'CARD_DECLINED__BAD_EXP',
  CARD_DECLINED__BAD_NUMBER: 'CARD_DECLINED__BAD_NUMBER',

  GROUP_NAME_ALREADY_TAKEN: 'GROUP_NAME_ALREADY_TAKEN',
  GROUP_FULL: 'GROUP_FULL',
  GROUP_LOCKED: 'GROUP_LOCKED',
  INVALID_PREPAID_TEAM_PASSWORD: 'INVALID_PREPAID_TEAM_PASSWORD',

  ISSUE_REFUND__MISSING_GROUP_FOR_TYPE: 'ISSUE_REFUND__MISSING_GROUP_FOR_TYPE',
  ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM: 'ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM',
  ISSUE_REFUND__INSUFFICIENT_FUNDS: 'ISSUE_REFUND__INSUFFICIENT_FUNDS',

  PHONE_NUMBER_TAKEN: 'PHONE_NUMBER_TAKEN',
  MISSING_AUTHY_TOKEN_OR_ID: 'MISSING_AUTHY_TOKEN_OR_ID',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_VERIFICATION_TOKEN: 'INVALID_VERIFICATION_TOKEN',
  AUHTY__UNKNOWN_SEND_TOKEN_ERROR: 'AUHTY__UNKNOWN_SEND_TOKEN_ERROR',

  USER_SIGNUP_MISSING_REQUIRED: 'USER_SIGNUP_MISSING_REQUIRED',

  ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND: 'ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND',
  ORGANIZATION_ADD_HOST__ALREADY_IS_HOST: 'ORGANIZATION_ADD_HOST__ALREADY_IS_HOST',

  PERMISSION_DENIED__REQUIRES_CAPTAIN_OR_ADMIN: 'PERMISSION_DENIED__REQUIRES_CAPTAIN_OR_ADMIN',
  PERMISSION_DENIED__REQUIRES_ORG_ADMIN: 'PERMISSION_DENIED__REQUIRES_ORG_ADMIN',

  RSVP__PLAYER_NOT_FOUND: 'RSVP__PLAYER_NOT_FOUND',
  RSVP__USER_NOT_FOUND: 'RSVP__USER_NOT_FOUND',

  REGISTRATION_NOT_OPEN: 'REGISTRATION_NOT_OPEN',
  REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',

  RENTAL_NOT_FOUND: 'RENTAL_NOT_FOUND',
  RENTAL_DELETED: 'RENTAL_DELETED',
  RENTAL_CHECKIN_NOT_FOUND: 'RENTAL_CHECKIN_NOT_FOUND',
  RENTAL_REGISTRANT_NOT_FOUND: 'RENTAL_REGISTRANT_NOT_FOUND',
  RENTAL_REGISTRATION_NOT_FOUND: 'RENTAL_REGISTRATION_NOT_FOUND',
  RENTAL_RESERVATION_NOT_FOUND: 'RENTAL_RESERVATION_NOT_FOUND',
  RENTAL_RESERVATION_CHECK_SIGNED_IN: 'RENTAL_RESERVATION_CHECK_SIGNED_IN',
  RENTAL_RSVP_NOT_FOUND: 'RENTAL_RSVP_NOT_FOUND',
  RENTAL_CHARGE_NOT_FOUND: 'RENTAL_CHARGE_NOT_FOUND',
  RENTAL_TIMESLOT_NOT_FOUND: 'RENTAL_TIMESLOT_NOT_FOUND',
  RENTAL_IMAGES_REQUIRED: 'RENTAL_IMAGES_REQUIRED',
  RENTAL_CREATION_ERROR: 'RENTAL_CREATION_ERROR',
  DUPLICATE_RENTAL: 'DUPLICATE_RENTAL',
  RENTAL_INVALID_START_END_TIME_INPUT: 'RENTAL_INVALID_START_END_TIME_INPUT',
  RENTAL_INVALID_TIME_RANGE: 'RENTAL_INVALID_TIME_RANGE',
  RENTAL_INVALID_NUM_GUESTS: 'RENTAL_INVALID_NUM_GUESTS',
  RENTAL_RESERVATIONS_EXIST_COURTS: 'RENTAL_RESERVATIONS_EXIST_COURTS',
  RENTAL_RESERVATIONS_EXIST_TIMES: 'RENTAL_RESERVATIONS_EXIST_TIMES',
  RENTAL_RESERVATIONS_EXIST_DATES: 'RENTAL_RESERVATIONS_EXIST_DATES',
  RENTAL_RESERVATIONS_EXIST: 'RENTAL_RESERVATIONS_EXIST',
  RENTAL_RESERVATION_TIMESLOTS_REQUIRED: 'RENTAL_RESERVATION_TIMESLOTS_REQUIRED',
  RENTAL_RESERVATION_DIFFERENT_VENUES: 'RENTAL_RESERVATION_DIFFERENT_VENUES',
  RENTAL_RESERVATION_CREATION_ERROR: 'RENTAL_RESERVATION_CREATION_ERROR',
  RENTAL_RESERVATION_PASSWORD_INCORRECT: 'RENTAL_RESERVATION_PASSWORD_INCORRECT',
  RENTAL_RESERVATION_FULL: 'RENTAL_RESERVATION_FULL',
  RENTAL_RESERVATION_ALREADY_REGISTERED: 'RENTAL_RESERVATION_ALREADY_REGISTERED',
  RENTAL_RESERVATION_INVALID_TIMES: 'RENTAL_RESERVATION_INVALID_TIMES',
  RENTAL_RESERVATION_UNPAID: 'RENTAL_RESERVATION_UNPAID',
  RENTAL_RESERVATION_PAST: 'RENTAL_RESERVATION_PAST',
  RENTAL_REGISTRANT_CREATION_ERROR: 'RENTAL_REGISTRANT_CREATION_ERROR',
  RENTAL_RSVP_CREATION_ERROR: 'RENTAL_RSVP_CREATION_ERROR',
  RENTAL_CHARGE_CREATION_ERROR: 'RENTAL_RSVP_CREATION_ERROR',
  RENTAL_TIMESLOT_UNAVAILABLE: 'RENTAL_TIMESLOT_UNAVAILABLE',
  RENTAL_COURT_NOT_FOUND: 'RENTAL_COURT_NOT_FOUND',

  SUBSCRIBE__UPGRADE_ORGANIZATION: 'SUBSCRIBE__UPGRADE_ORGANIZATION',
  SUBSCRIBE__NO_ORGANIZATION: 'SUBSCRIBE__NO_ORGANIZATION',
  SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION: 'SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION',
  SUBSCRIBE__NO_STRIPE_CUSTOMER: 'SUBSCRIBE__NO_STRIPE_CUSTOMER',
  SUBSCRIBE__UNKNOWN_ERROR: 'SUBSCRIBE__UNKNOWN_ERROR',
  SUBSCRIBE__HAS_PAST_MEMBERSHIP: 'SUBSCRIBE__HAS_PAST_MEMBERSHIP',
  SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE: 'SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE',
  RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION: 'RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION',
  RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION: 'RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION',
  RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE: 'RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE',
  RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED: 'RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED',
  RESUBSCRIBE__SUBSCRIPTION_NOT_FOUND: 'RESUBSCRIBE__SUBSCRIPTION_NOT_FOUND',
  UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP: 'UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP',
  UNSUBSCRIBE__UNKNOWN_ERROR: 'UNSUBSCRIBE__UNKNOWN_ERROR',

  MEMBER_REGISTER__NOT_DROPIN_PROGRAM: 'MEMBER_REGISTER__NOT_DROPIN_PROGRAM',
  MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION: 'MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION',
  MEMBER_REGISTER__LAST_CHARGE_FAILED: 'MEMBER_REGISTER__LAST_CHARGE_FAILED',

  EMAIL_FILTER_INPUT_ERROR: 'EMAIL_FILTER_INPUT_ERROR',
  EMAIL_TO_MISSING: 'EMAIL_TO_MISSING',
  MISSING_MESSAGE_CONTENT: 'MISSING_MESSAGE_CONTENT',
  RSVP_REMINDER_INVALID_INPUT: 'RSVP_REMINDER_INVALID_INPUT',
  RSVP_REMINDER_FAILED: 'RSVP_REMINDER_FAILED',

  USER_NOT_SIGNED_IN: 'USER_NOT_SIGNED_IN',

  INVALID_FILE_PROVIDED: 'INVALID_FILE_PROVIDED',

  SELF_WITHDRAWAL_INVALID_PROGRAM_TYPE: 'SELF_WITHDRAWAL_INVALID_PROGRAM_TYPE',
  SELF_WITHDRAWAL_PLAYER_NOT_REGISTERED: 'SELF_WITHDRAWAL_PLAYER_NOT_REGISTERED',
  SELF_WITHDRAWAL_AFTER_GAME_START: 'SELF_WITHDRAWAL_AFTER_GAME_START',

  PLAYER_STRIKE_NOT_FOUND: 'PLAYER_STRIKE_NOT_FOUND',

  UPDATE_USER_PLATFORM_CONTEXT_ERROR: 'UPDATE_USER_PLATFORM_CONTEXT_ERROR',

  CLIPBOARD_COPY_ERROR: 'CLIPBOARD_COPY_ERROR',

  VOLO_FITNESS__INVALID_ORGANIZATION: 'VOLO_FITNESS__INVALID_ORGANIZATION',
  VOLO_FITNESS__INVALID_PROGRAM_PRICING: 'VOLO_FITNESS__INVALID_PROGRAM_PRICING',

  REFERRALS_USER_NOT_ELIGIBLE_TO_REFER: 'REFERRALS_USER_NOT_ELIGIBLE_TO_REFER',

  VENUE_INVALID_LOCATION: 'VENUE_INVALID_LOCATION',
  SELF_CHECK_IN_EARLY: 'SELF_CHECK_IN_EARLY',
  SELF_CHECK_IN_LATE: 'SELF_CHECK_IN_LATE',
  SELF_CHECK_IN_FAR: 'SELF_CHECK_IN_FAR',
  SELF_CHECK_IN_ERROR: 'SELF_CHECK_IN_ERROR',
  SELF_CHECK_IN_DISABLED: 'SELF_CHECK_IN_DISABLED',
  SELF_CHECK_IN_EXISTS: 'SELF_CHECK_IN_EXISTS',
  SELF_CHECK_IN_UNAUTHORIZED: 'SELF_CHECK_IN_UNAUTHORIZED',
});

export const ErrorMessageEnum = Object.freeze({
  [ErrorCodes.UNKNOWN_ERROR]:
    'An unknown error has occurred. Please contact info@volosports.com if the issue persists',
  [ErrorCodes.UPDATE_FAILED]: 'An error occurred while trying to update',
  [ErrorCodes.NOT_IMPLEMENTED]:
    "This feature isn't implemented yet! Please reach out to a Volo team member for help.",
  [ErrorCodes.USER_INFO_LOAD_FAILED]: 'An error occurred while trying to load the user data',
  [ErrorCodes.AUTHORIZATION_FAILED]: "You don't have permission to make this request.",
  [ErrorCodes.UNAUTHORIZED_ACTION]: 'You are not authorized to take this action.',
  [ErrorCodes.INVALID_OBJECT_ID]:
    "Whoops, we were unable to find what you're looking for! Please double check the URL.",
  [ErrorCodes.INVALID_TEAM_ID]: 'No teamId found!',
  [ErrorCodes.USERNAME_TAKEN]: '🧐 That username is already in use.',
  [ErrorCodes.EMAIL_TAKEN]: '🧐 That email is already associated with a Volo account.',
  [ErrorCodes.DEEPLINK_ERROR]: 'Error creating deep link',
  [ErrorCodes.CANT_DELETE_TEAM_WITH_GAMES]: "Can't delete a team that has games.",
  [ErrorCodes.PLAYER_ALREADY_IN_LEAGUE]:
    'This player is already registered in the league you are trying to move them to.',
  [ErrorCodes.GROUP_ALREADY_IN_LEAGUE]: 'New program already includes at least one group player.',
  [ErrorCodes.DUPLICATE_TEAM_NAME]: 'Two teams cannot have the same name.',
  [ErrorCodes.ACTIVITY_TYPE_NOT_FOUND]: 'Unrecognized activity type.',
  [ErrorCodes.SEASON_NOT_FOUND]: 'Invalid season.',
  [ErrorCodes.ORGANIZATION_NOT_FOUND]: 'Organization not found.',
  [ErrorCodes.USER_NOT_FOUND]: 'User not found.',
  [ErrorCodes.LEAGUE_NOT_FOUND]: 'League not found.',
  [ErrorCodes.GROUP_NOT_FOUND]: 'Group not found.',
  [ErrorCodes.PROMO_NOT_FOUND]: 'Promo code not found',
  [ErrorCodes.INCORRECT_PROMO_TYPE]: 'Incorrect Promo Code type',
  [ErrorCodes.REFERRAL_MISSING_CREATOR]: 'Referral code is missing a creator',
  [ErrorCodes.INVALID_DATE_RANGE]: 'Invalid date range provided',
  [ErrorCodes.INVALID_TIMESLOT_TIME_RANGE]: 'Invalid time range provided',
  [ErrorCodes.INVALID_DATE_STRING_FORMAT]: 'Invalid date. Expected YY/MM/DD',
  [ErrorCodes.INVALID_TIME_STRING_FORMAT]: 'Invalid time. Expected HH:mm',
  [ErrorCodes.INVALID_PRICE_FORMAT]: 'Invalid price. Expected price in cents.',
  [ErrorCodes.INVALID_GAME_TIMES]: 'Invalid game start or end time.',
  [ErrorCodes.STRIPE_ADD_PAYMENT_SOURCE_ERROR]: 'Unable to add payment.',
  [ErrorCodes.STRIPE_DELETE_PAYMENT_SOURCE_ERROR]: 'Unable to delete payment.',
  [ErrorCodes.STRIPE_DEFAULT_PAYMENT_SOURCE_ERROR]: 'Unable to set default payment.',
  [ErrorCodes.DUPLICATE_REGISTRANT]: "Looks like you're already registered for this program.",
  [ErrorCodes.DUPLICATE_DROPIN]: "Looks like you've already registered for this game.",
  [ErrorCodes.DROPIN_CLAIMED]: 'Sorry, this drop in has already been claimed.',
  [ErrorCodes.DROPIN_CLOSED]:
    'This drop in was closed. If you think this was an error, please contact your captain or support.',
  [ErrorCodes.DROPIN_NOT_FOUND]: 'Unable to find associated drop in slot.',
  [ErrorCodes.NO_REGISTRANT_FOUND]: 'Unable to find a registrant for this drop in slot.',
  [ErrorCodes.NO_DROPIN_REGISTRANT_FOUND]: 'No registration found for this drop in.',
  [ErrorCodes.DROPIN_CLOSING]: 'Error closing drop-in slot',
  [ErrorCodes.DROPIN_HOLD]:
    'This slot is currently held by a player. Please wait for them to release it.',
  [ErrorCodes.DROPIN_MAX]: 'Max number of drop-in slots already reached for this game.',
  [ErrorCodes.DROPIN_OPENING]: 'Error opening drop-in slot',
  [ErrorCodes.UNAUTHORIZED_DROPIN_UPDATE]:
    'You must be a team captain or an admin to update a drop in slot.',
  [ErrorCodes.RECURRING_DROPIN_FAILED]: 'Recurring drop in creation failed',
  [ErrorCodes.RECURRING_DROPIN_GAME_COUNT]:
    'You entered a higher number than there are upcoming games.',
  [ErrorCodes.RECURRING_DROPIN_BAD_INPUT]:
    'Received invalid input for editing/creating a recurring drop-in',
  [ErrorCodes.NO_TOURNAMENT_DROPINS]: "Sorry, tournaments don't currently allow drop ins.",
  [ErrorCodes.INVALID_DROPIN_GENDER]:
    'This drop in is limited to players of a certain gender. If you think this error is a mistake contact info@volosports.com',
  [ErrorCodes.INVALID_PROGRAM_GENDER]:
    'This program is limited to players of a certain gender. If you think this error is a mistake, please contact info@volosports.com',
  [ErrorCodes.INVALID_RATING_TYPE]:
    "Sorry, but this rating type isn't allowed. If this issue continues, please contact support.",
  [ErrorCodes.NO_VOLO_PASS]:
    'Sorry, this event is not accepting players not enrolled in Volo Pass.',
  [ErrorCodes.INVALID_NUM_WEEKS]: 'Invalid number of weeks. Expected at least 1.',
  [ErrorCodes.INVALID_TEAM_SIZE]:
    'The number of player per team and teams per league should be between 1 and 100',
  [ErrorCodes.VENUE_NOT_FOUND]: 'This league does not have a location 🗺',
  [ErrorCodes.INVALID_CONTACT_INFO]:
    'This league needs a point of contact with a valid email and phone number',

  [ErrorCodes.GAME_NOT_FOUND]: 'Unable to find associated game.',
  [ErrorCodes.SCHEDULE_NOT_FOUND]: 'Unable to find associated schedule.',
  [ErrorCodes.RECURRING_DROPIN_NOT_FOUND]: 'Recurring drop-in not found',
  [ErrorCodes.NOTIFICATION_NOT_FOUND]: 'Notification not found',
  [ErrorCodes.NEIGHBORHOOD_NOT_FOUND]: 'Neighborhood not found',

  [ErrorCodes.CARD_DECLINED__AUTHENTICATION_REQUIRED]:
    'Your card required authentication. Please add a different payment source and try again.',
  [ErrorCodes.CARD_DECLINED__CONTACT_ISSUER]:
    'There was a problem with your card. Please reach out to your card issuer or use a different payment method.',
  [ErrorCodes.CARD_DECLINED__TRY_AGAIN]:
    'There was a problem with your card. Please try again. If the issue persists, you may need to reach out to your card issuer.',
  [ErrorCodes.CARD_DECLINED__DUPLICATE_CHARGE]:
    "A duplicate charge was detected, so we didn't complete the charge. If this is in error, wait a moment and try again.",
  [ErrorCodes.CARD_DECLINED__EXPIRED]: 'Your card is expired.',
  [ErrorCodes.CARD_DECLINED__SUSPICIOUS]:
    'Your card has been flagged as suspicious. Please contact your card issuer or use a different payment method.',
  [ErrorCodes.CARD_DECLINED__BAD_CVC]: 'Your CVC is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_PIN]: 'Your pin is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_ZIP]: 'Your zip code is incorrect.',
  [ErrorCodes.CARD_DECLINED__INSUFFICIENT_FUNDS]: 'Your card has insufficient funds.',
  [ErrorCodes.CARD_DECLINED__BAD_EXP]: 'Your expiration date is invalid.',
  [ErrorCodes.CARD_DECLINED__BAD_NUMBER]: 'Your card number is invalid.',
  [ErrorCodes.CARD_DECLINED__REASON_UNKNOWN]:
    'There was an unknown problem with your card. Please try a different payment method.',
  [ErrorCodes.CARD_DECLINED__TRY_AGAIN_LATER]:
    'Your card has exceeded its velocity limit. Please try again later or use a different payment method.',
  [ErrorCodes.UNKNOWN_CHARGE_ERROR]:
    'There was an unknown problem completing the charge. Please try again, use an alternate payment method, or contact info@volosports.com for help.',

  [ErrorCodes.GROUP_FULL]: 'Sorry, that group is full! Please choose another.',
  [ErrorCodes.GROUP_LOCKED]:
    'This group is locked. Please reach out to your team captain and have them reach out to Customer Service',
  [ErrorCodes.GROUP_NAME_ALREADY_TAKEN]:
    'Another group/team in the league already has this name! Please pick a different name.',
  [ErrorCodes.INVALID_PREPAID_TEAM_PASSWORD]: 'Incorrect password.',

  [ErrorCodes.ISSUE_REFUND__MISSING_GROUP_FOR_TYPE]: 'Missing group for refund type.',
  [ErrorCodes.ISSUE_REFUND__PARTIAL_REFUND_EXCEEDS_MAXIMUM]: 'Refund amount exceeds maximum.',
  [ErrorCodes.ISSUE_REFUND__INSUFFICIENT_FUNDS]:
    'Your account has insufficient funds to issue this refund.',

  [ErrorCodes.PHONE_NUMBER_TAKEN]: 'Phone number is already associated with a Volo account',
  [ErrorCodes.MISSING_AUTHY_TOKEN_OR_ID]: 'Missing authy ID or token from verify token mutation',
  [ErrorCodes.INVALID_PHONE_NUMBER]: 'Unrecognized phone number.',
  [ErrorCodes.INVALID_VERIFICATION_TOKEN]: 'Invalid verification token.',
  [ErrorCodes.AUHTY__UNKNOWN_SEND_TOKEN_ERROR]:
    'Sorry, there was an unknown issue sending a verification text. Please double check you entered your phone number correctly. If you continue experiencing issues, please reach out to info@volosports.com.',

  [ErrorCodes.USER_SIGNUP_MISSING_REQUIRED]: 'Missing required information to create an account.',

  [ErrorCodes.ORGANIZATION_ADD_HOST__EMAIL_NOT_FOUND]:
    'No Volo account was found with the provided email.',
  [ErrorCodes.ORGANIZATION_ADD_HOST__ALREADY_IS_HOST]:
    'The user is already a host for another organization on Volo. Hosts can only have one organization.',

  [ErrorCodes.PERMISSION_DENIED__REQUIRES_ORG_ADMIN]:
    'You must be an organization admin to take this action.',
  [ErrorCodes.PERMISSION_DENIED__REQUIRES_CAPTAIN_OR_ADMIN]:
    'You must be a team captain or an admin to take this action.',

  [ErrorCodes.RSVP__PLAYER_NOT_FOUND]: "Unable to find a player's RSVP associated with this event.",
  [ErrorCodes.RSVP__USER_NOT_FOUND]: "Unable to find user's RSVP associated with this event.",

  [ErrorCodes.REGISTRATION_NOT_OPEN]: 'Registration is not yet open.',
  [ErrorCodes.REGISTRATION_CLOSED]: 'Registration is closed.',

  [ErrorCodes.RENTAL_NOT_FOUND]: 'Rental not found.',
  [ErrorCodes.RENTAL_DELETED]: 'Rental has been deleted.',
  [ErrorCodes.RENTAL_CHECKIN_NOT_FOUND]: 'Rental check-in not found.',
  [ErrorCodes.RENTAL_REGISTRANT_NOT_FOUND]: 'Rental registrant not found.',
  [ErrorCodes.RENTAL_REGISTRATION_NOT_FOUND]: 'Rental registration not found.',
  [ErrorCodes.RENTAL_RESERVATION_NOT_FOUND]: 'Rental reservation not found.',
  [ErrorCodes.RENTAL_RESERVATION_CHECK_SIGNED_IN]:
    'Rental reservation not found. Please double-check that you are logged into your account and have a valid reservation id',
  [ErrorCodes.RENTAL_RSVP_NOT_FOUND]: 'Rental RSVP not found.',
  [ErrorCodes.RENTAL_CHARGE_NOT_FOUND]: 'Rental charge not found.',
  [ErrorCodes.RENTAL_TIMESLOT_NOT_FOUND]: 'Rental timeslot not found.',
  [ErrorCodes.RENTAL_IMAGES_REQUIRED]: 'An image is required for rentals.',
  [ErrorCodes.RENTAL_CREATION_ERROR]: 'An error occurred while creating the rentals.',
  [ErrorCodes.DUPLICATE_RENTAL]:
    'A rental has already been created for the passed in sport/venue combo, please edit instead.',
  [ErrorCodes.RENTAL_INVALID_START_END_TIME_INPUT]:
    'Invalid rental input. Check the start and end time inputs to make sure they are valid.',
  [ErrorCodes.RENTAL_INVALID_TIME_RANGE]:
    'Invalid time range! There are missing time slots within the given range',
  [ErrorCodes.RENTAL_INVALID_NUM_GUESTS]: 'Invalid number of guests provided.',
  [ErrorCodes.RENTAL_RESERVATIONS_EXIST_COURTS]:
    "You have active reservations on the courts you're attempting to edit. Please either cancel those reservations or consider making other changes.",
  [ErrorCodes.RENTAL_RESERVATIONS_EXIST_TIMES]:
    "You have active reservations in the time frame you're attempting to edit. Please either cancel those reservations or consider making other changes.",
  [ErrorCodes.RENTAL_RESERVATIONS_EXIST_DATES]:
    "You have active reservations in the calendar selection you're attempting to edit. Please either cancel those reservations or consider making other changes.",
  [ErrorCodes.RENTAL_RESERVATIONS_EXIST]:
    'Reservations already exist for this rental. Please adjust accordingly and then try again.',
  [ErrorCodes.RENTAL_RESERVATION_TIMESLOTS_REQUIRED]:
    'Cannot create a reservation without timeslots.',
  [ErrorCodes.RENTAL_RESERVATION_DIFFERENT_VENUES]:
    'Cannot create a booking at different venues simultaneously.',
  [ErrorCodes.RENTAL_RESERVATION_CREATION_ERROR]:
    'An error occurred while creating the rental reservations.',
  [ErrorCodes.RENTAL_RESERVATION_PASSWORD_INCORRECT]: 'Incorrect password.',
  [ErrorCodes.RENTAL_RESERVATION_FULL]: 'The rental is full.',
  [ErrorCodes.RENTAL_RESERVATION_ALREADY_REGISTERED]:
    'You are already booked for this rental! Please return to Discover to book additional time.',
  [ErrorCodes.RENTAL_RESERVATION_INVALID_TIMES]: 'Invalid reservation start or end time.',
  [ErrorCodes.RENTAL_RESERVATION_UNPAID]: 'This reservation has not been paid for yet!',
  [ErrorCodes.RENTAL_RESERVATION_PAST]:
    'This reservation has passed, so you can no longer register for it. Please reach out to customer support if you have any questions or concerns.',
  [ErrorCodes.RENTAL_REGISTRANT_CREATION_ERROR]:
    'An error occurred while creating the rental registrants.',
  [ErrorCodes.RENTAL_RSVP_CREATION_ERROR]: 'An error occurred while creating the rental RSVPs.',
  [ErrorCodes.RENTAL_CHARGE_CREATION_ERROR]: 'An error occurred while creating the rental charge.',
  [ErrorCodes.RENTAL_TIMESLOT_UNAVAILABLE]:
    'A timeslot is unavailable! It may have been deleted or already reserved.',
  [ErrorCodes.RENTAL_COURT_NOT_FOUND]: 'The provided court does not exist on this rental.',

  [ErrorCodes.SUBSCRIBE__UPGRADE_ORGANIZATION]:
    'You may only upgrade your membership within the same organization',
  [ErrorCodes.SUBSCRIBE__NO_ORGANIZATION]:
    'You must have either a valid homeOrganization or stripe metadata organizationId to upgrade your membership. Please contact customer support.',
  [ErrorCodes.SUBSCRIBE__USER_HAS_ACTIVE_SUBSCRIPTION]: 'You already have an active membership.',
  [ErrorCodes.SUBSCRIBE__NO_STRIPE_CUSTOMER]: 'Memberships require a valid payment method.',
  [ErrorCodes.SUBSCRIBE__HAS_PAST_MEMBERSHIP]:
    'You are unable to start a trial because you have a past membership.',
  [ErrorCodes.SUBSCRIBE__UNSUPPORTED_TRIAL_TYPE]:
    'The specified trial type is not supported. Reach out for help getting your trial started!',
  [ErrorCodes.SUBSCRIBE__UNKNOWN_ERROR]:
    'Unknown error starting your membership. Please reach out for help!',
  [ErrorCodes.RESUBSCRIBE__NO_ACTIVE_SUBSCRIPTION]: 'No active membership found.',
  [ErrorCodes.RESUBSCRIBE__NO_ACTIVE_CANCELLED_SUBSCRIPTION]: 'No cancelled membership found.',
  [ErrorCodes.RESUBSCRIBE__SUBSCRIPTION_IS_INACTIVE]:
    'Cannot undo cancellation of an inactive membership. Please re-register for membership.',
  [ErrorCodes.RESUBSCRIBE__SUBSCRIPTION_NOT_CANCELED]:
    'Cannot reinstate a membership that is active.',
  [ErrorCodes.RESUBSCRIBE__SUBSCRIPTION_NOT_FOUND]: 'No subscription item found to reinstate.',
  [ErrorCodes.UNSUBSCRIBE__USER_HAS_NO_ACTIVE_MEMBERSHIP]: 'No active membership found',
  [ErrorCodes.UNSUBSCRIBE__UNKNOWN_ERROR]:
    'Unknown error cancelling your membership. Please reach out for help!',

  [ErrorCodes.MEMBER_REGISTER__NOT_DROPIN_PROGRAM]:
    'Program is not valid for free member registration.',
  [ErrorCodes.MEMBER_REGISTER__NO_ACTIVE_SUBSCRIPTION]: 'No active membership.',
  [ErrorCodes.MEMBER_REGISTER__LAST_CHARGE_FAILED]:
    'We were unable to complete your last membership charge. Please check the payment method on file. If this is in error, contact support for assistance.',

  [ErrorCodes.EMAIL_FILTER_INPUT_ERROR]: 'Invalid email filter input.',
  [ErrorCodes.EMAIL_TO_MISSING]: 'Cannot send custom email: missing "to" emails list.',
  [ErrorCodes.MISSING_MESSAGE_CONTENT]: 'Cannot send empty custom message',
  [ErrorCodes.RSVP_REMINDER_INVALID_INPUT]: 'RSVP Reminder received invalid input',
  [ErrorCodes.RSVP_REMINDER_FAILED]: 'RSVP Reminder sending failed',

  [ErrorCodes.USER_NOT_SIGNED_IN]: 'The user is not signed in.',

  [ErrorCodes.INVALID_FILE_PROVIDED]: 'Could not parse the file provided.',

  [ErrorCodes.STRIPE_CUSTOMER_NOT_FOUND]: 'Could not find a Stripe customer for that user.',
  [ErrorCodes.STRIPE_SUBSCRIPTION_NOT_FOUND]: 'Could not find that subscription in Stripe.',
  [ErrorCodes.STRIPE_COUPON_NOT_FOUND]: 'Could not find the specified Stripe coupon.',
  [ErrorCodes.STRIPE_MULTIPLE_SUBSCRIPTION_ITEM_IDS]:
    'Multiple Stripe subscription items were returned while trying to update. Please contact customer support for assistance.',

  [ErrorCodes.SELF_WITHDRAWAL_INVALID_PROGRAM_TYPE]:
    'You cannot self withdraw from this type of program!',
  [ErrorCodes.SELF_WITHDRAWAL_PLAYER_NOT_REGISTERED]:
    'You may only withdraw from programs you have registered for!',
  [ErrorCodes.SELF_WITHDRAWAL_AFTER_GAME_START]:
    'You cannot withdraw from a game that has already started.',

  [ErrorCodes.PLAYER_STRIKE_NOT_FOUND]: 'Player strike not found.',

  [ErrorCodes.UPDATE_USER_PLATFORM_CONTEXT_ERROR]:
    'There was an error updating the user platform context. Could not update the client version.',

  [ErrorCodes.CLIPBOARD_COPY_ERROR]: 'Unable to copy link. Please try again.',

  [ErrorCodes.VOLO_FITNESS__INVALID_ORGANIZATION]:
    'This organization does not support Volo Fitness.',
  [ErrorCodes.VOLO_FITNESS__INVALID_PROGRAM_PRICING]:
    'Volo Fitness pricing is only allowed for classes and series.',

  [ErrorCodes.REFERRALS_USER_NOT_ELIGIBLE_TO_REFER]:
    "You're not eligible to refer someone to Volo. Sign up for a program first in order to be eligible for referrals!",

  [ErrorCodes.VENUE_INVALID_LOCATION]: 'Venue has invalid location data.',
  [ErrorCodes.SELF_CHECK_IN_EARLY]:
    "It looks like you're a bit early! Please wait until at least 45 minutes before game time to check-in.",
  [ErrorCodes.SELF_CHECK_IN_LATE]: 'The game has ended and you may no longer check-in.',
  [ErrorCodes.SELF_CHECK_IN_FAR]:
    "It looks like you're not at your game yet! Please wait until you arrive at the venue to check-in.",
  [ErrorCodes.SELF_CHECK_IN_ERROR]:
    'Please try again or find a staff member to check-in with your QR code.',
  [ErrorCodes.SELF_CHECK_IN_DISABLED]: 'Self check-in is disabled for this venue.',
  [ErrorCodes.SELF_CHECK_IN_EXISTS]: 'You have already checked in for this game.',
  [ErrorCodes.SELF_CHECK_IN_UNAUTHORIZED]: 'You are not authorized to check in for this game.',
});

export default ErrorCodes;
