import PropTypes from 'prop-types';
import { ColorProps, PricePhaseProps } from '../common-props';

const RegistrationProps = PropTypes.shape({
  _id: PropTypes.string,
  jersey_colors: PropTypes.arrayOf(ColorProps),
  min_team_size: PropTypes.number,
  max_team_size: PropTypes.number,
  min_team_amount: PropTypes.number,
  max_team_amount: PropTypes.number,
  phases: PropTypes.shape({
    earlyBird: PricePhaseProps,
    regular: PricePhaseProps,
    lastChance: PricePhaseProps,
  }),
  voloPassPricingAllowed: PropTypes.bool,
  memberPrice: PropTypes.number,
  nonMemberPrice: PropTypes.number,
  memberDropinPrice: PropTypes.number,
  registration_open: PropTypes.string,
  registration_close: PropTypes.string,
  registrationOpenStr: PropTypes.string,
  registrationCloseStr: PropTypes.string,
  allowPrepaidTeams: PropTypes.bool,
  projectedNumOfTeams: PropTypes.number,
  projectedNumOfPlayers: PropTypes.number,

  malesAllowed: PropTypes.number,
  femalesAllowed: PropTypes.number,
  freeAgentsAllowed: PropTypes.number,
  freeAgentLimitsEnabled: PropTypes.bool,
  blockFreeAgents: PropTypes.bool,
  allowWaitlist: PropTypes.bool,
  genderLimitsEnabled: PropTypes.bool,
  blockGroupCaptains: PropTypes.bool,
  blockGroupMembers: PropTypes.bool,
  blockPrepaidTeamCaptains: PropTypes.bool,
  blockPrepaidTeamMembers: PropTypes.bool,

  status: PropTypes.string,
  currentPhase: PropTypes.string,
  currentSize: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,

  limit_individual_registrations: PropTypes.bool,
  block_individual_registrations: PropTypes.bool,
  block_group_captain_registrations: PropTypes.bool,
  block_group_member_registrations: PropTypes.bool,
  block_prepaid_team_captain_registrations: PropTypes.bool,
  block_prepaid_team_member_registrations: PropTypes.bool,
  block_all_registrations: PropTypes.bool,
});

export default RegistrationProps;
